export const environment = {
  pro: true,
  environmentName: 'UAT',
  jwtValidation: false,
  urlServ: "https://bca-ws-uat.gnp.com.mx/",
  urlServApi: 'https://api-uat.oscp.gnp.com.mx/',
  urlServApiGee: 'https://apigee-uat.oscpuat.gnp.com.mx/',
  clientIdOAuth: 'BAA5Qjmxu4l8Q1qhMak8A7alkFIE47vTYWdfTIXgFk7spSN0',
  clientSecretOAuth: 'N8P6sc5I4ZYkQYLm9aG5t5r8kQwoz29Dlq6rxL89elTiAdsSwOzsPzAPdWARoI6N'
};
