export class Constants {
  //Cookie name
  public static readonly ACCESS_TOKEN_COOKIE = 'AccessTokenOAuth';
  public static readonly REFRESH_TOKEN_COOKIE = 'RefreshTokenOAuth';

  //Header
  public static readonly CONTENT_TYPE_APLICATION_JSON: string =
    'Content-Type: application/json';
  public static readonly ACCEPT: string = 'Accept:/';
  public static readonly REFRESH_TOKEN = 'refresh_token';

  //Apikey name
  public static readonly APBONOSSEG: string = 'apBonosSeg';
  public static readonly APCONSULTABONOS: string = 'apConsultaBonos';

  //path api
  public static readonly PATH_OAUTH_TOKEN: string = 'oauth/token';
  public static readonly PATH_API_DISPATCHER: string = 'bca/api/dispatcher';
  public static readonly PATH_SEG_PASS: string = 'bca-bonos/BCABonosSegundoPwd';
  public static readonly PATH_CONSULTA_BONOS: string = 'consulta-bonos';

  //Metodo HTTP
  public static readonly GET: string = 'GET';
  public static readonly POST: string = 'POST';
}
