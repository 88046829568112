import { LoadingComponent } from '@/app/components/loading/loading.component';
import { ToastComponent } from '@/app/components/toast/toast.component';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment from 'moment-timezone';
import { DatosCuaService } from '../../service/datosCua.service';

@Component({
  selector: 'app-segmentos',
  templateUrl: './segmentos.component.html'
})
export class SegmentosComponent implements OnInit {
  cua: string = '';
  router: any;
  arregloSegmentos: any = [];
  anio: number = 0;
  rol: string = '';
  trimestre: any = '';
  cuatrimestre: any = '';
  sufijoTri: any = '';
  sufijoCuatri: any = '';
  ramos: any = [];
  _ban_portalEntrada: string = '';

  @ViewChild('toastContainer', { read: ViewContainerRef, static: true })
  toastContainer!: ViewContainerRef;

  constructor(
    private _location: Location,
    public serviceCua: DatosCuaService,
    public dialog: MatDialog,
    public route: Router
  ) {}

  ngOnInit() {
    this.cua = sessionStorage.getItem('cua')!;
    this.rol = sessionStorage.getItem('rol')!;
    this._ban_portalEntrada = sessionStorage.getItem('tipousuario')!;
    if (history.state.data !== undefined) {
      this.arregloSegmentos = history.state.data.data;
      this.ramos = this.obtienRamos(this.arregloSegmentos);
      this.anio = history.state.anio;
      this.obtienePrefijo();
      this.entidades();
    } else {
      this.anio = history.state.anio;
      this.cambioAnio(this.anio);
      this.obtienePrefijo();
    }
  }

  showError(message: string, icon: string) {
    const componentRef = this.toastContainer.createComponent(ToastComponent);
    componentRef.instance.message = message;
    componentRef.instance.show = true;
    componentRef.instance.icon = icon;

    // Opcional: Ocultar el toast automáticamente después de unos segundos
    setTimeout(() => {
      this.toastContainer.clear();
    }, 5000); // El toast se cerrará después de 3 segundos
  }

  cambiarSufijoPeriodo(periodo: any) {
    if (periodo === 1) {
      return 'er';
    }
    if (periodo === 2) {
      return 'do';
    }
    if (periodo === 3) {
      return 'er';
    }
    if (periodo === 4) {
      return 'to';
    }
    return '';
  }

  entidades() {
    this.arregloSegmentos.forEach((enti: any) => {
      if (enti.entidadNueva) {
        enti.esEntidad = true;
        enti.id = enti.entidadNueva;
        var ramoFake = {
          id: enti.entidadNueva,
          ramo: enti.etiqueta,
          corte:
            this.convertirValor(enti.tipoDato, enti.valor) + ' ' + enti.sufijo,
          cveRamo: [null],
          nombre: enti.segmento
        };
        enti.ramos.push(ramoFake);
      }
    });
  }

  convertirValor(tipoDato: any, valor: any) {
    if (valor === null) {
      valor = 0;
    }
    if (tipoDato === 'texto') {
      return valor;
    }
    if (tipoDato === 'moneda') {
      var signonegativo = '';
      valor = parseFloat(valor);

      var numformateado = new Intl.NumberFormat('es-MX').format(
        valor.toFixed(2)
      );

      if (Math.sign(Number(valor)) === -1) {
        numformateado = numformateado.replace('-', '');
        signonegativo = '-';
      }
      return (
        signonegativo +
        '$' +
        numformateado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      );
    }
    if (tipoDato === 'porcentaje') {
      valor = parseFloat(valor);

      return this.truncar(valor * 100, 4) + '%';
    }
    if (tipoDato === 'numero') {
      valor = parseFloat(valor);
      return new Intl.NumberFormat('es-MX').format(valor.toFixed(4));
    }
    return valor;
  }

  truncar(x: any, posiciones = 0) {
    var s = x.toString();
    var decimalLength = s.indexOf('.') + 1;
    var numStr = s.substr(0, decimalLength + posiciones);
    return Number(numStr);
  }

  goBack() {
    this._location.back();
  }

  public color(clave: any) {
    if (clave === null || clave === undefined) {
      return 'naranja-oscuro';
    }
    if (clave.includes('PY')) {
      return 'rojo';
    } else if (clave.includes('LC')) {
      return 'rojo-oscuro';
    } else if (clave.includes('VIDA')) {
      return 'verde';
    } else if (clave.includes('DANOS')) {
      return 'morado';
    } else if (clave.includes('AUTOS')) {
      return 'naranja';
    } else if (clave.includes('SALUD')) {
      return 'azul';
    }
    return 'rojo';
  }

  public convertirCorteMenu(corte: any) {
    const arrayMes = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ];
    var dividirFecha = corte.split('/', 3);
    return (
      'Corte al ' +
      parseInt(dividirFecha[0]) +
      ' de ' +
      arrayMes[parseInt(dividirFecha[1]) - 1] +
      ' ' +
      parseInt(dividirFecha[2].slice(-2))
    );
  }

  obtienRamos(arreglo: any) {
    var contadorRamo = 0;
    let ram: any = [];
    arreglo.forEach((segmento: any) => {
      if (segmento.ramos.length > 0) {
        segmento.ramos.forEach((ramo: any) => {
          contadorRamo++;
          ramo.ordenRamo = contadorRamo;
          ram.push(ramo);
        });
      }
    });
    return ram;
  }

  public cambioAnio(anio: any) {
    this.ramos = [];
    anio = Number(anio);
    let loadingCua = this.dialog.open(LoadingComponent);
    this.serviceCua.getRamosSegmentos(this.cua, anio, this.rol).subscribe(
      (dataSeg: any) => {
        if (!dataSeg.message) {
          this.anio = anio;
          this.arregloSegmentos = dataSeg.data;
          this.entidades();
          this.ramos = this.obtienRamos(this.arregloSegmentos);
          loadingCua.close();
        } else {
          this.showError(
            dataSeg.message,
            'iziToast-icon fas fa-exclamation revealIn'
          );
          loadingCua.close();
        }
      },
      (error) => {
        this.showError(
          'Error al cargar el servicio. Intenta de nuevo.',
          'iziToast-icon fas fa-exclamation revealIn'
        );
        loadingCua.close();
      }
    );
  }

  obtienePrefijo() {
    this.trimestre = Math.ceil(parseInt(moment().format('M')) / 3);
    this.cuatrimestre = Math.ceil(parseInt(moment().format('M')) / 4);
    this.sufijoTri = this.cambiarSufijoPeriodo(this.trimestre);
    this.sufijoCuatri = this.cambiarSufijoPeriodo(this.cuatrimestre);
  }

  calcularPeriodo(ramo: any, anio: any) {
    var periodo = parseInt(ramo.periodo);
    var trimestre = Math.ceil(parseInt(moment().format('M')) / 3);
    var cuatrimestre = Math.ceil(parseInt(moment().format('M')) / 4);
    if (parseInt(anio) === parseInt(moment().format('YYYY'))) {
      if (ramo.tipoPeriodo === 'cuatri' && periodo > cuatrimestre) {
        periodo = cuatrimestre;
      }
      if (ramo.tipoPeriodo === 'tri' && periodo > trimestre) {
        periodo = trimestre;
      }
    }
    return periodo;
  }

  verDetalles(ramo: any) {
    let loadingCua = this.dialog.open(LoadingComponent);
    var periodo = this.calcularPeriodo(ramo, this.anio);
    this.serviceCua
      .getDetalleSub(
        ramo.cveSegmento,
        ramo.cveRamo[0],
        this.cua,
        this.anio,
        ramo.periodo,
        this.rol
      )
      .subscribe(
        (dataSeg: any) => {
          if (!dataSeg.message) {
            loadingCua.close();

            this.route.navigate(['/ramo'], {
              state: {
                data: dataSeg,
                ramo: ramo.cveRamo[0],
                segmento: ramo.cveSegmento,
                anio: this.anio,
                tipoPeriodo: ramo.tipoPeriodo,
                titulo: ramo.ramo,
                ramos: this.ramos,
                periodoMaximo: ramo.periodo,
                periodo: periodo
              }
            });
          } else {
            this.showError(
              dataSeg.message,
              'iziToast-icon fas fa-exclamation revealIn'
            );
            loadingCua.close();
          }
        },
        (error) => {
          this.showError(
            'Error al cargar el servicio. Intenta de nuevo.',
            'iziToast-icon fas fa-exclamation revealIn'
          );
          loadingCua.close();
        }
      );
  }

  verEntidad(ramo: any, segmento: any) {
    let loadingCua = this.dialog.open(LoadingComponent);
    this.serviceCua.getRamoEntidad(ramo, this.cua, this.rol).subscribe(
      (dataSeg: any) => {
        if (!dataSeg.message) {
          loadingCua.close();
          this.route.navigate(['/ramo'], {
            state: {
              data: dataSeg,
              entidad: ramo,
              nombre: segmento,
              ramos: this.ramos,
              anio: this.anio,
              esEntidad: true
            }
          });
        } else {
          this.showError(
            dataSeg.message,
            'iziToast-icon fas fa-exclamation revealIn'
          );
          loadingCua.close();
        }
      },
      (error) => {
        this.showError(
          'Error al cargar el servicio. Intenta de nuevo.',
          'iziToast-icon fas fa-exclamation revealIn'
        );
        loadingCua.close();
      }
    );
  }
}
