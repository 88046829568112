import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authSevice = inject(AuthService);

  let valid = authSevice.isValid();
  if (!valid) {
    router.navigate(['bonos'], { queryParamsHandling: 'preserve' });
    return false;
  }
  return valid;
};
