export const environment = {
  pro: true,
  environmentName: 'UAT',
  jwtValidation: false,
  urlApiLogin: 'https://login-bca-dot-gnp-baseunicaagentes-uat.appspot.com',
  apikey : '04985d13-d1d4-4032-9fe6-faa14c18d464',
  apikeyPass : 'l7xxfc209c472e674250b649edf9381d0ea9',
  urlServ: "https://bca-ws-uat.gnp.com.mx/",
  consultaBonosUrl: "consulta-bonos",
  urlServApi: 'https://api-uat.oscp.gnp.com.mx/',
  bcaBonos: 'bca-bonos/'
};
