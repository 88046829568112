import { environment } from '@/environments/environment';
import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

declare const Buffer: any;


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {


  public decodedToken: any = '';
  public jwt: string = '';
  public tokenDecifrado:any = '';


  constructor(
    public router: Router,
    private route: ActivatedRoute

  ) {

   }


   intercept(req: HttpRequest<any>, next: HttpHandler): any {
    let tokenReq: any;
        const headersapplication = new HttpHeaders ({
          'Content-Type': 'application/json',
          'x-api-key': environment.apikey
        });
        tokenReq = req.clone({
          headers: headersapplication,
        }); 
    return next.handle(req);
  }

/*   LoginSesion<T>() {
    if(sessionStorage.getItem('tipousuario') == 'intermediario'){
      this.router.navigate(['/login-intermediario']);
    }else{
      this.router.navigate(['/auth']);
    }
  }  */
  
  LoginSesion<T>() {
      this.router.navigate(['/auth']);
  } 

  ObtieneLtpaToken(){
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        const params = new URLSearchParams(fragment.split('?')[1]);
  
        const portal = params.get('portal');
        const idpart = params.get('idpart');
        const cua = params.get('cua');
  
        console.log('Portal:', portal); 
        console.log('IDPart:', idpart);
        console.log('CUA:', cua);
        sessionStorage.setItem('idparticipante', idpart!);
        sessionStorage.setItem('tipousuario', portal!);
        sessionStorage.setItem('cua', cua!);
        this.LoginSesion();
      }
    });

  }


  } 
  
