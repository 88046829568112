import { AuthService } from '@/app/service/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss'
})

export class AuthComponent implements OnInit, OnDestroy {
  private readonly subs: Subscription[] = [];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly authSrv: AuthService
  ) {
    this.subs.push(
      this.authSrv.validSession$.subscribe((validSession: any) => {
        setTimeout(() => {

          if(sessionStorage.getItem('tipousuario') == 'intermediario'){
            this.router.navigate(['/login-intermediario']);
          }else{
            this.router.navigate(
              [validSession.isValid ? '/home' : 'forbidden'],
              {
                queryParamsHandling: 'preserve'
              }
            );
          }
        }, validSession.delayMillis);
      })
    );
  }

  ngOnInit(): void {
    this.authSrv.auth();
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
