import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable()
export class DatosCuaService {
  idParticipante: string = '';

  constructor(
    private http: HttpClient,
    private _router: Router
  ) {
    this.idParticipante = sessionStorage.getItem('idparticipante')!;
  }

  getRol(idCua: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.apikey
      })
    };
    return this.http.get(
      `${environment.urlServ}${environment.consultaBonosUrl}/tipo-rol/${idCua}`,
      httpOptions
    );
  }

  getRamosSegmentos(idCua: any, anio: number, rol: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.apikey
      })
    };
    return this.http.get(
      `${environment.urlServ}${environment.consultaBonosUrl}/ramos-segmentos-p/${idCua}?anio=${anio}&rol=${rol}&sistema=web`,
      httpOptions
    );
  }

  getRamoEntidad(idRamo: string, idCua: any, rol: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.apikey
      })
    };
    return this.http.get(
      `${environment.urlServ}${environment.consultaBonosUrl}/detalles-nueva-entidad/${idRamo}/${idCua}?rol=${rol}&sistema=web`,
      httpOptions
    );
  }

  getDetalleSub(
    cveSegmento: any,
    cveRamo: any,
    idCua: any,
    anio: any,
    periodo: any,
    rol: any
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.apikey
      })
    };
    return this.http.get(
      `${environment.urlServ}${environment.consultaBonosUrl}/detalles-ramos-p/${cveSegmento}/${cveRamo}/${this.idParticipante}/${idCua}?anio=${anio}&periodo=${periodo}&rol=${rol}&sistema=web`,
      httpOptions
    );
  }
  getDetalleResumen(
    cveSubSegmento: any,
    cveRamo: any,
    idCua: any,
    resumenId: any,
    componenteId: any,
    anio: any,
    periodo: any,
    rol: any
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.apikey
      })
    };
    return this.http.get(
      `${environment.urlServ}${environment.consultaBonosUrl}/resumen-componente-p/${cveSubSegmento}/${cveRamo}/${this.idParticipante}/${idCua}/${resumenId}/${componenteId}?anio=${anio}&periodo=${periodo}&rol=${rol}&sistema=web`,
      httpOptions
    );
  }

  getResumenBono(
    cveSubSegmento: any,
    cveRamo: any,
    idCua: any,
    componenteId: any,
    anio: any,
    periodo: any,
    rol: any
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.apikey
      })
    };
    return this.http.get(
      `${environment.urlServ}${environment.consultaBonosUrl}/resumen-bonos-p/${cveSubSegmento}/${cveRamo}/${this.idParticipante}/${idCua}/${componenteId}?anio=${anio}&periodo=${periodo}&rol=${rol}&sistema=web`,
      httpOptions
    );
  }

  getDetalleBono(
    cveSubSegmento: any,
    cveRamo: any,
    idCua: any,
    componenteId: any,
    anio: any,
    periodo: any,
    rol: any
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.apikey
      })
    };
    return this.http.get(
      `${environment.urlServ}${environment.consultaBonosUrl}/detalle-bono-p/${cveSubSegmento}/${cveRamo}/${this.idParticipante}/${idCua}/${componenteId}?anio=${anio}&limite=1000000&pagina=1&periodo=${periodo}&rol=${rol}&sistema=web&zona=226`,
      httpOptions
    );
  }

  getBonos(idCua: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.apikey
      })
    };
    return this.http.get(
      `${environment.urlServ}${environment.consultaBonosUrl}/tipo-rol/${idCua}`,
      httpOptions
    );
  }

  postSegundoPwd(password: string, cua: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apikey: environment.apikeyPass
      })
    };

    const payload = {
      bcaBonosSegundoPwd: {
        password: password,
        cua: cua
      }
    };

    return this.http.post(
      `${environment.urlServApi}${environment.bcaBonos}BCABonosSegundoPwd`,
      payload,
      httpOptions
    );
  }

  getBonosAnio(componenteId: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.apikey
      })
    };
    return this.http.get(
      `${environment.urlServ}${environment.consultaBonosUrl}/recuperando_el_anio/${componenteId}`,
      httpOptions
    );
  }
  getDetalleRamPerio(
    cveSubSegmento: any,
    cveRamo: any,
    idCua: any,
    anio: any,
    periodo: any,
    rol: any
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.apikey
      })
    };
    return this.http.get(
      `${environment.urlServ}${environment.consultaBonosUrl}/resumen-componente-p/${cveSubSegmento}/${cveRamo}/${this.idParticipante}/${idCua}?anio=${anio}&periodo=${periodo}&rol=${rol}&sistema=web`,
      httpOptions
    );
  }
}
