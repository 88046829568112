import { environment } from '@/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';

const LPTA2_COOKIE_NAME = 'LtpaToken2';
const JWT_COOKIE_NAME = 'jwt-bonos';
const ID_PART_COOKIE_NAME = 'idpart';

const BONOADMIN = 'BONOSADMINISTRADOR';

const DELAY_MILLIS = 1500;

const rolesCatalog: string[] = [BONOADMIN];

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _validSource = new Subject<any>();
  private _validSession = {
    isValid: false,
    delayMillis: 0
  };

  public validSession$ = this._validSource.asObservable();

  set validSession(params: any) {
    this._validSession = {
      ...this._validSession,
      ...params
    };
    this._validSource.next(this._validSession);
  }

  get validSession() {
    return this._validSession;
  }

  constructor(
    private readonly cookieSrv: CookieService,
    private readonly http: HttpClient
  ) {
    this._validSession = {
      isValid: this.validSessionByJwt(),
      delayMillis: 0
    };
  }

  public isValid(): boolean {
    return this._validSession.isValid;
  }

  public validSessionByJwt(idParticipante: any = null): boolean {
    let idpart = idParticipante;
    if (!idpart && this.cookieSrv.check(ID_PART_COOKIE_NAME)) {
      idpart = this.cookieSrv.get(ID_PART_COOKIE_NAME);
    }

    if (this.cookieSrv.check(JWT_COOKIE_NAME) && idpart) {
      let user: any = jwtDecode(this.cookieSrv.get(JWT_COOKIE_NAME));
      if (
        (environment.jwtValidation && idpart == user.idparticipante) ||
        !environment.jwtValidation
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public auth(): void {
    const idpart = sessionStorage.getItem('idparticipante');
    if (this.validSessionByJwt(idpart)) {
      this.validSession = {
        isValid: true,
        delayMillis: DELAY_MILLIS
      };
    } else {
      this.getCookie(idpart);
    }
  }

  private getCookie(idpart: any) {
    if (this.cookieSrv.check(LPTA2_COOKIE_NAME)) {
      this.refreshLtpaToken(this.cookieSrv.get(LPTA2_COOKIE_NAME)).subscribe({
        next: (r: any) => {
          let domain = '.gnp.com.mx';
          if (window.location.hostname === 'localhost') {
            domain = 'localhost';
          }
          let today = moment().add(1, 'hours').toDate();
          this.cookieSrv.set(JWT_COOKIE_NAME, r.jwt, today, '/', domain);
          this.cookieSrv.set(ID_PART_COOKIE_NAME, idpart, today, '/', domain);

          let user: any = jwtDecode(r.jwt);
          if (
            (environment.jwtValidation && idpart == user.idparticipante) ||
            !environment.jwtValidation
          ) {
            this.validSession = {
              isValid: true,
              delayMillis: 0
            };
          } else {
            this.validSession = {
              isValid: false
            };
          }
        },
        error: (e) => {
          this.validSession = {
            isValid: false
          };
        }
      });
    } else {
      this.validSession = {
        isValid: false
      };
    }
  }

  private refreshLtpaToken(ltpa: string) {
    return this.http.post(`${environment.urlApiLogin}/loginLtpaToken`, {
      cookie: ltpa
    });
  }
}
