<div #toastContainer></div>
<div class="container-fluid bg-gris">

  <!--                 ||||||||||titulo blanco|||||||||||||-->
  <div class="contenedor-titulo-detalles">
    <div class="row justify-content-center align-items-center">
      <div class="col-md-12">
        <button class="btn-texto btn-flat" (click)="regresarBono()">
          <mat-icon class="color-icon">arrow_back</mat-icon>
        </button>
      </div>
      <div class="col-md-3 ">
      </div>
      <div class="col-md-6 div-titulo-anio">
        <span class="titulo-Negritas">{{payload.titulo}}</span>
        <span class="pipe {{payload.color}}" style="margin-left: 2px;">|</span>
        <span class="titulo-Negritas negritas {{payload.color}}" style="margin-left: 2px;">{{payload.ramo}}</span>
      </div>
      <div class="col-md-3 text-right">
      </div>
    </div>
  </div>
  <!--                 ||||||||||titulo blanco|||||||||||||-->


  <div class="separacion-interseccion-3"></div>


  <!--                 ||||||||||tiempos trimestres meses |||||||||||||-->
  <div class="row text-center justify-content-center ">
    <div class="col-md-7 Xoffset-4">
      <div class="row align-items-center ">
        <div class="col-md-3 col-sm-3 sin-padding">
          <button class="btn-icono btn-flat" id="btn-anio-izquierda">
            <mat-icon class="chevron_color" (click)="cambioPeriodo(periodo - 1)">chevron_left</mat-icon>
          </button>
        </div>
        <div class="col-md-6 col-sm-6 sin-padding">
          <div>{{periodo}}{{periodoSufijo}} {{tipoPeriodoMostrar}} &nbsp; <span
              class="titulo-Negritas">{{periodoMes}}</span>
          </div>
        </div>
        <div class="col-md-3 col-sm-3 sin-padding">
          <button class="btn-icono btn-flat" id="btn-anio-derecha">
            <mat-icon class="chevron_color" (click)="cambioPeriodo(periodo + 1)">chevron_right</mat-icon>
          </button>
        </div>
      </div>

      <div class="text-center">
        {{convertirCorte(corte)}}
      </div>

      <div class="text-center row" *ngIf='payload.detalles'>
        <div class="col-md-6">
          Generación: <span class="titulo-Negritas">{{generacion}}</span>
        </div>
        <div *ngIf="tipo != '0'" class="col-md-6">
          Tipo: <span class="titulo-Negritas" *ngIf="tipo != '0'">{{tipo}}</span>
        </div>
      </div>
    </div>
  </div>
  <!--                 ||||||||||fin tiempos trimestres meses |||||||||||||-->


  <div class="separacion-interseccion-3"></div>

  <div class="row ">
    <div class="col-md-10 offset-md-1" *ngIf="esBonoDetalle || esEntidad ">

      <!--                 ||||||||||tarjeta datos |||||||||||||-->
      <div class="tarjeta-blanca-datos esquinas-redondas">
        <div class="row" *ngFor="let det of detalles">
          <div class="col-md-6 col-sm-6 padding-10-0">
            <div class="contenedor-icono"></div><span class="">{{det.nombre}}</span>
          </div>
          <div class="col-md-6 col-sm-6 padding-10-0 seccion2-heder">
            <div class="titulo-Negritas negritas azul" *ngIf="!det.resumen">{{componenteBono(det)}}</div>
            <div class="titulo-Negritas negritas azul" *ngIf="det.resumen">{{componenteDatos(det)}}</div>
            <div class="detalles " *ngIf="det.tieneDetalle" (click)="detalleResumen(det)">Ver
              detalle</div>
          </div>

        </div>

      </div>
    </div>
    <!--                 ||||||||||fin tarjeta datos |||||||||||||-->



    <!--                 ||||||||||tarjeta faltantes |||||||||||||-->
    <div class="col-md-10 offset-md-1" *ngIf="payload.faltante?.componentes?.length >0">
      <div class="tarjeta-blanca-datos esquinas-redondas">
        <div class="row">
          <div class="col-md-6 col-sm-6 padding-10-0">
            <div class="contenedor-icono"></div><span class="negritas azul">{{payload.faltante.titulo}}</span>
          </div>
        </div>

        <div class="row" *ngFor="let componente of payload.faltante.componentes">
          <div class="col-md-6 col-sm-6 padding-10-0">
            <div class="contenedor-icono"></div><span class="">{{componente.nombre}}</span>
          </div>
          <div class="col-md-6 col-sm-6 padding-10-0 seccion2-heder">
            <div class="titulo-Negritas negritas naranja ">
              {{convertirValor(componente.tipoDato,componente.faltante)}}</div>
          </div>
        </div>
      </div>
    </div>
    <!--                 ||||||||||tarjeta faltantes |||||||||||||-->

    <!--                 ||||||||||tarjeta totales |||||||||||||-->
    <div class="col-md-10 offset-md-1" *ngIf="payload.totales?.length > 0">
      <div class="tarjeta-blanca-datos esquinas-redondas row padding-20 15">

        <div class="{{(payload.totalesSeparadosPorId.length > 0) ? 'col-md-12':'col-md-5'}}"
          *ngIf="payload.totalesSeparadosPorId.length > 0">
          <div class="row  tooltipped">
            <div class="{{(payload.totalesSeparadosPorId.length == 1) ? 'col-md-12':'col-md-6'}} mostrarTotal"
              *ngFor="let eltot of payload.totalesSeparadosPorId">
              <div class="text-center">{{eltot.nombre}}</div>
              <div class="text-center negritas azul">{{convertirValor(eltot.tipo,eltot.valor)}}</div>

            </div>
            <div class="barraTotal {{payload.color}} col-md-12"></div>

          </div>
        </div>

      </div>
      <!--                 ||||||||||tarjeta totales |||||||||||||-->
    </div>


    <div class="separacion-interseccion-2"></div>

    <div class="container" *ngIf="payloadTabla?.tieneTabla">
      <div class="row mb-3">
        <div class="col-md-3 d-flex">
          <button class="dt-button buttons-excel buttons-html5" (click)="exportar()" tabindex="0"
            aria-controls="tabladata" type="button">
            <span class="exportar-excel">Exportar Lista &nbsp;&nbsp;
              <i class="fas fa-file-export"></i></span>
          </button>
        </div>
        <div class="col-md-9 d-flex justify-content-end align-items-center">
          <!-- Chips seleccionados, inicialmente ocultos, y mostrados cuando haya selección -->
          <div *ngIf="selectedColumns.length > 0" class="d-flex align-items-center me-2">
            <mat-chip-listbox>
              <mat-chip class="matChip" style="background:#fd7e14 !important; color:whitesmoke !important;"
                *ngFor="let column of selectedColumns" (removed)="removeColumn(column)">{{ column.label }}
                <mat-icon style="color:whitesmoke!important; font-size:15px!important;" matChipRemove>close</mat-icon>
              </mat-chip>
            </mat-chip-listbox>
          </div>

          <!-- Botón de Filtros y el input de búsqueda alineados horizontalmente -->
          <button class="celdaTituloFiltrado me-2" mat-button (click)="openColumnFilter()">Filtros</button>
          <input type="text" class="form-control w-25" [(ngModel)]="searchText" placeholder="Buscar..." />
        </div>
      </div>

      <!-- Contenedor de la tabla con barra de desplazamiento -->
      <div class="table-container" style="max-height: 400px; overflow-y: auto;">
        <app-dynamic-table [columnas]="payloadTabla?.detalles?.columnas"
          [filas]="(payloadTabla?.detalles?.filas || []) | filter: searchText : filteredColumns : payloadTabla?.detalles?.columnas"
          [tieneTabla]="payloadTabla?.tieneTabla">
        </app-dynamic-table>


      </div>
    </div>
    <div class="row" *ngIf="!payloadTabla.tieneTabla">
      <div class="col-md-12 text-center titulo1">
        No hay información a mostrar
      </div>
    </div>



  </div>