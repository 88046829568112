import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Constants } from '../utils/Constants';

interface PeticionConfig {
  url: string;
  ap: string;
  method: string;
  cabeceras: string[];
  payload?: string;
}

@Injectable()
export class DatosCuaService {
  idParticipante: string = '';
  urlDispatcher: string = `${environment.urlServ}${Constants.PATH_API_DISPATCHER}`;

  constructor(private http: HttpClient) {
    this.idParticipante = sessionStorage.getItem('idparticipante')!;
  }

  getRol(idCua: any): Observable<any> {
    const request = this.mapPayload(
      `${environment.urlServ}${Constants.PATH_CONSULTA_BONOS}/tipo-rol/${idCua}`,
      Constants.APCONSULTABONOS,
      Constants.GET
    );
    return this.http.post(this.urlDispatcher, request);
  }

  getRamosSegmentos(idCua: any, anio: number, rol: any): Observable<any> {
    const request = this.mapPayload(
      `${environment.urlServ}${Constants.PATH_CONSULTA_BONOS}/ramos-segmentos-p/${idCua}?anio=${anio}&rol=${rol}&sistema=web`,
      Constants.APCONSULTABONOS,
      Constants.GET
    );
    return this.http.post(this.urlDispatcher, request);
  }

  getRamoEntidad(idRamo: string, idCua: any, rol: any): Observable<any> {
    const request = this.mapPayload(
      `${environment.urlServ}${Constants.PATH_CONSULTA_BONOS}/detalles-nueva-entidad/${idRamo}/${idCua}?rol=${rol}&sistema=web`,
      Constants.APCONSULTABONOS,
      Constants.GET
    );
    return this.http.post(this.urlDispatcher, request);
  }

  getDetalleSub(
    cveSegmento: any,
    cveRamo: any,
    idCua: any,
    anio: any,
    periodo: any,
    rol: any
  ): Observable<any> {
    const request = this.mapPayload(
      `${environment.urlServ}${Constants.PATH_CONSULTA_BONOS}/detalles-ramos-p/${cveSegmento}/${cveRamo}/${this.idParticipante}/${idCua}?anio=${anio}&periodo=${periodo}&rol=${rol}&sistema=web`,
      Constants.APCONSULTABONOS,
      Constants.GET
    );
    return this.http.post(this.urlDispatcher, request);
  }
  getDetalleResumen(
    cveSubSegmento: any,
    cveRamo: any,
    idCua: any,
    resumenId: any,
    componenteId: any,
    anio: any,
    periodo: any,
    rol: any
  ): Observable<any> {
    const request = this.mapPayload(
      `${environment.urlServ}${Constants.PATH_CONSULTA_BONOS}/resumen-componente-p/${cveSubSegmento}/${cveRamo}/${this.idParticipante}/${idCua}/${resumenId}/${componenteId}?anio=${anio}&periodo=${periodo}&rol=${rol}&sistema=web`,
      Constants.APCONSULTABONOS,
      Constants.GET
    );
    return this.http.post(this.urlDispatcher, request);
  }

  getResumenBono(
    cveSubSegmento: any,
    cveRamo: any,
    idCua: any,
    componenteId: any,
    anio: any,
    periodo: any,
    rol: any
  ): Observable<any> {
    const request = this.mapPayload(
      `${environment.urlServ}${Constants.PATH_CONSULTA_BONOS}/resumen-bonos-p/${cveSubSegmento}/${cveRamo}/${this.idParticipante}/${idCua}/${componenteId}?anio=${anio}&periodo=${periodo}&rol=${rol}&sistema=web`,
      Constants.APCONSULTABONOS,
      Constants.GET
    );
    return this.http.post(this.urlDispatcher, request);
  }

  getDetalleBono(
    cveSubSegmento: any,
    cveRamo: any,
    idCua: any,
    componenteId: any,
    anio: any,
    periodo: any,
    rol: any
  ): Observable<any> {
    const request = this.mapPayload(
      `${environment.urlServ}${Constants.PATH_CONSULTA_BONOS}/detalle-bono-p/${cveSubSegmento}/${cveRamo}/${this.idParticipante}/${idCua}/${componenteId}?anio=${anio}&limite=1000000&pagina=1&periodo=${periodo}&rol=${rol}&sistema=web&zona=226`,
      Constants.APCONSULTABONOS,
      Constants.GET
    );
    return this.http.post(this.urlDispatcher, request);
  }

  getBonos(idCua: any): Observable<any> {
    const request = this.mapPayload(
      `${environment.urlServ}${Constants.PATH_CONSULTA_BONOS}/tipo-rol/${idCua}`,
      Constants.APCONSULTABONOS,
      Constants.GET
    );
    return this.http.post(this.urlDispatcher, request);
  }

  postSegundoPwd(password: string, cua: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    //Se prepara el body
    const payloadDecoded = {
      bcaBonosSegundoPwd: {
        password: password,
        cua: cua
      }
    };

    //Se hace encode al payload
    let payloadEncoded = btoa(JSON.stringify(payloadDecoded));

    //Se prepara el request al api del dispatcher
    const req = this.mapPayload(
      `${environment.urlServApi}${Constants.PATH_SEG_PASS}`,
      Constants.APBONOSSEG,
      Constants.POST,
      payloadEncoded
    );

    // Se realiza la petición
    return this.http.post(this.urlDispatcher, req, httpOptions);
  }

  getBonosAnio(componenteId: any): Observable<any> {
    const request = this.mapPayload(
      `${environment.urlServ}${Constants.PATH_CONSULTA_BONOS}/recuperando_el_anio/${componenteId}`,
      Constants.APCONSULTABONOS,
      Constants.GET
    );
    return this.http.post(this.urlDispatcher, request);
  }
  getDetalleRamPerio(
    cveSubSegmento: any,
    cveRamo: any,
    idCua: any,
    anio: any,
    periodo: any,
    rol: any
  ): Observable<any> {
    const request = this.mapPayload(
      `${environment.urlServ}${Constants.PATH_CONSULTA_BONOS}/resumen-componente-p/${cveSubSegmento}/${cveRamo}/${this.idParticipante}/${idCua}?anio=${anio}&periodo=${periodo}&rol=${rol}&sistema=web`,
      Constants.APCONSULTABONOS,
      Constants.GET
    );
    return this.http.post(this.urlDispatcher, request);
  }

  mapPayload(
    url: string,
    apikey: string,
    method: string,
    payloadEncoded?: string
  ) {
    if (method == 'POST') {
      const payload: PeticionConfig = {
        url: url,
        ap: apikey,
        method: Constants.POST,
        cabeceras: [Constants.CONTENT_TYPE_APLICATION_JSON, Constants.ACCEPT],
        payload: payloadEncoded
      };
      return payload;
    } else {
      const payload: PeticionConfig = {
        url: url,
        ap: apikey,
        method: Constants.GET,
        cabeceras: [Constants.CONTENT_TYPE_APLICATION_JSON, Constants.ACCEPT]
      };
      return payload;
    }
  }
}
