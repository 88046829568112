import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from 'src/app/service/guard/auth.guard';
import { AuthComponent } from './view/auth/auth.component';
import { ForbiddenComponent } from './view/forbidden/forbidden.component';
import { HomeComponent } from './view/home/home.component';
import { LoginIntermediarioComponent } from './view/login-intermediario/login-intermediario.component';
import { RamoComponent } from './view/ramo/ramo.component';
import { SegmentosComponent } from './view/segmentos/segmentos.component';
import { VerDetalleBonoComponent } from './view/ver-detalle-bono/ver-detalle-bono.component';
import { VerDetalleComponent } from './view/ver-detalle/ver-detalle.component';


const routes: Routes = [
  { path: 'auth', component: AuthComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'home', component: HomeComponent ,canActivate: [authGuard]},
  { path: 'segmentos', component: SegmentosComponent,canActivate: [authGuard]  },
  { path: 'ramo', component: RamoComponent,canActivate: [authGuard]  },
  { path: 'ver-detalle', component: VerDetalleComponent,canActivate: [authGuard]  },
  { path: 'ver-det-bono', component: VerDetalleBonoComponent,canActivate: [authGuard]  },
  { path: 'login-intermediario', component: LoginIntermediarioComponent},
  { path: '**', redirectTo: 'auth', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
